@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#121212];
}

#Navbar {
  backdrop-filter: saturate(0) !important;
  backdrop-filter: blur(0) !important;
  background-color: transparent !important;
  border: none !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: none;
  box-shadow: none;
  padding: 0;
  width: fit-content !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:active {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}
.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.Mui-focused
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:active {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}
.MuiOutlinedInput-notchedOutline,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiSelect-select,
.MuiSelect-outlined,
.MuiInputBase-input,
.MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
  padding-top: 8px !important;
}

.slick-dots {
  @apply !mb-12;
}

.slick-dots li button::before {
  @apply !text-white !opacity-100 !text-[14px];
}
.slick-dots .slick-active button::before {
  @apply !text-gold !opacity-100 !text-[14px] scale-125;
}

.inputs label {
  @apply !text-white;
}
.inputs input {
  @apply w-full h-full bg-transparent  font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-white disabled:border-0 disabled:cursor-not-allowed transition-all border-b placeholder-shown:border-blue-gray-200 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm pt-4 pb-1.5 border-white focus:border-white text-white;
}

.inputs button {
  @apply align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg border border-gold text-gold hover:opacity-75 focus:ring focus:ring-white active:opacity-[0.85];
}

/* .filters label {
  @apply text-white before:border-white after:border-white;
} */

.custom-select {
  --mt-select-color: #d3a188; /* Replace with your custom color */
}
